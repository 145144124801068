import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  // { name: 'getCarriers', url: `${environment.madagascarApiUrl}/carriers`, method: 'get'},
  { name: 'getPickups', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups`, method: 'get' },
  { name: 'getAdminPickups', url: `${environment.puconApiUrl}/pickups`, method: 'get' },
  { name: 'getPickup', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups/{id}`, method: 'get'},
  { name: 'downloadPickups', url: `${environment.puconApiUrl}/pickups-download`, method: 'post'},
  { name: 'getPickupLogs', url: `${environment.puconApiUrl}/shippers/{shipper_id}/pickups/{id}/logs`, method: 'get'},
  { name: 'getCarriers', url: `${environment.shanghaiApiUrl}/tracking-carriers`, method: 'get'},
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get'},
  { name: 'getOrganizations', url: `${environment.dubaiApiUrl}/organizations`, method: 'get'},
  { name: 'getCarriersNY', url: `${environment.newyorkApiUrl}/v2/coverage/carriers`, method: 'get' },
  { name: 'getCarrierServicesNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services`, method: 'get' },
  { name: 'postExcelUpload', url: `${environment.puconApiUrl}/massive-pickups`, method: 'put', headers: {
    'Content-Type': 'multipart/form-data'
  }},
  {
    name: 'reinjectPickups',
    url: `${environment.puconApiUrl}/integration-management/pickups`,
    method: 'post'
  }
]

export default class PickupsService {
  constructor (context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }

  async uploadFileExcel (name, params = {}, user = {}, type) {
    let formData = new FormData()
    formData = this.dataUser(formData, user)
    formData.append('file', params.file)
    formData.append('email', params.email)
    const service = services.filter(service => service.name === name)[0]
    // Se añade el tipo a la request
    service.url = `${service.url}?type=${type}`
    return useJwt.genericUploadFile(service, formData, params, true)
  }
  
  dataUser (formData, dataUser) {
    formData.append('user_email', dataUser.email ?? '')
    formData.append('first_name', dataUser.first_name ?? dataUser.fullName.split(' ')[0])
    formData.append('last_name', dataUser.last_name ?? dataUser.fullName.split(' ')[1])
    formData.append('role', dataUser.role ?? '')
    return formData
  }
}