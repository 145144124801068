<template>
  <div>
    <b-modal id="modalPickupLogs" title="Registros del retiro" ok-variant="outline-success" ok-only ok-title="Cerrar" centered>
      <b-alert class="p-1" variant="primary" show>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
          <span>Envíame conserva los datos de los últimos 90 días de forma predeterminada.</span>
        </div>
      </b-alert>
      <h5 class="mt-1">Creación</h5>
      <table-render :schema="schema" :rows="rowsCreation" :actions="actionsCreation"></table-render>
      <h5 class="mt-1">Tracking</h5>
      <table-render :schema="schema" :rows="rowsTracking" :actions="actionsTracking"></table-render>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'modal-pickup-logs',
  props: ['pickup'],
  data () {
    return {
      rowsTracking: [],
      rowsCreation: [],
      actionsCreation: [],
      actionsTracking: [],
      schema: []
    }
  },
  watch: {
    pickup () {
      this.rowsCreation = this.generateRows(this.pickup.creation)
      this.rowsTracking = this.generateRows(this.pickup.tracking)
    }
  },
  mounted () {
    this.initializeData()
  },
  methods: {
    initializeData () {
      this.schema = [
        { label: 'Archivo', sortable: true, key: 'name' },
        { label: '...', sortable: true, key: 'actions', class: ['text-center'] }
      ]
      this.actionsCreation = [{ action: (id) => this.goToLogUrl(id, this.rowsCreation), icon: 'LinkIcon', color: 'warning', text: 'Ver archivo' }]
      this.actionsTracking = [{ action: (id) => this.goToLogUrl(id, this.rowsTracking), icon: 'LinkIcon', color: 'warning', text: 'Ver archivo' }]
    },
    generateRows (pickup) {
      return pickup.map((file, index) => {
        const logs = {
          name: file.name,
          url: file.url,
          id: index
        }
        return logs
      })
    },
    goToLogUrl (id, rows) {
      const selectedLog = rows.filter((el) => el.id === id)[0]
      window.open(selectedLog.url)
    }
  }
}
</script>
