<template>
  <div>
    <b-modal id="modalPickupDetail" size="lg" :title="$t('Detalle de retiro')" ok-variant="outline-success"
    ok-only :ok-title="$t('Cerrar')" centered>
      <div v-if="!loading">
        <div class="row" v-if="pickup && pickup.identifier">
          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Identificador')}}</b></label>
            <span>{{pickup.identifier || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Estado')}}</b></label>
            <span>{{(pickup.status ? pickup.status.name : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Seller')}}</b></label>
            <span>{{(pickup.company ? pickup.company.name : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Bodega')}}</b></label>
            <span>{{(pickup.warehouse ? pickup.warehouse.name : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Contacto')}}</b></label>
            <span>{{(pickup.contact ? pickup.contact.name : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Teléfono')}}</b></label>
            <span>{{(pickup.contact ? pickup.contact.phone : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Courier')}}</b></label>
            <span>{{(pickup.carrier ? pickup.carrier.name : '--') || '--'}}</span>
          </div>

          <!-- Servicio de Courier -->
          <div class="col-sm-12 col-md-4 mb-1 container-info" v-if="pickup.service_type">
            <label><b>{{$t('Servicio de Courier')}}</b></label>
            <span>{{ pickup.service_type.name }}</span>
          </div>

          <!-- Usuario creador -->
          <div class="col-sm-12 col-md-4 mb-1 container-info" v-if="pickup.creator_user">
            <label><b>{{$t('Usuario Creador')}}</b></label>
            <span>{{ `${pickup.creator_user.first_name} ${pickup.creator_user.first_name} - ${pickup.creator_user.role}` }}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('N° de reserva')}}</b></label>
            <span>{{pickup.carrier_pickup_number || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Dirección retiro')}}</b></label>
            <span>{{(pickup.address ? pickup.address.full_address : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Place_level3_pickup')}}</b></label>
            <span>{{(pickup.address ? pickup.address.place : '--') || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Tipo de retiro')}}</b></label>
            <span>{{pickup.type.name || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Fecha de retiro')}}</b></label>
            <span>{{pickup.pickup_date_formatted || '--'}}</span>
          </div>

          <div class="col-sm-12 col-md-4 mb-1 container-info">
            <label><b>{{$t('Fecha de creación')}}</b></label>
            <span>{{pickup.created_at || '--'}}</span>
          </div>

          <!-- Evaluacion -->
          <div v-if="pickup.evaluation" class="row">
              <div class="col-md-12 text-center mb-1">
                <h5>{{$t('Detalles de evaluación')}}</h5>
              </div>
              <div class="col-sm-12 col-md-4 mb-1 container-info">
                <label><b>{{$t('Nombre del usuario')}}</b></label>
                <span>{{pickup.evaluation.user.full_name || '--'}}</span>
              </div>
              <div class="col-sm-12 col-md-4 mb-1 container-info">
                <label><b>{{$t('Correo del usuario')}}</b></label>
                <span>{{pickup.evaluation.user.email || '--'}}</span>
              </div>
              <div class="col-sm-12 col-md-4 mb-1 container-info">
                <label><b>{{$t('Origen de evaluación')}}</b></label>
                <span>{{evaluationOriginLabel || '--'}}</span>
              </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="loading">
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
        <div class="col-sm-12 col-md-4 mb-1"><b-skeleton width="100px" class="mt-1 mb-1"/><b-skeleton width="250px"/></div>
      </div>
    </b-modal>
  </div>
</template>
<script>
//import moment from 'moment'
export default {
  name: 'modal-pickup-detail',
  props: ['pickup'],
  data () {
    return {
      loading: true
    }
  },
  computed: {
    evaluationOriginLabel() {
      if (this.pickup.evaluation && this.pickup.evaluation.origin) {
        const origin = this.pickup.evaluation.origin
        if (origin === 'platform') {
          return this.$t('Plataforma')
        } else if (origin === 'public') {
          return this.$t('Vista pública')
        }
      }
      return '--'
    }
  },
  watch: {
    pickup () {
      this.pickup.pickup_date_formatted = this.$options.filters.dbDateUTCToGMTlocal(this.pickup.pickup_date, 'DD/MM/YYYY')
      if (this.pickup.evaluation) {
        const { user } = this.pickup.evaluation
        this.pickup.evaluation.user.full_name = `${user.first_name} ${user.last_name}`

      }
    }
  },
  mounted () {
  }
}
  
</script>
<style lang="scss">
  
</style>