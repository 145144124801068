<template>
  <div>
    <b-modal id="modalPickupUpload" :title="modalTitle" modal-class="custom-dialog dialog-600" ok-variant="outline-primary" :ok-title="modalTitle" @ok="ok" @close="close" centered no-close-on-esc no-close-on-backdrop ok-only>
      <div class="mt-0">
        <form-render :fields.sync="fields"
          :form.sync="formUpload"
          ref="formUpload"
          @send="onAccept"
          :key="keyFormRender">
          <template #slot1>
            <div>
              <b-media-body >
                <label for="file">Archivo de importación <span class="text-danger">*</span> 
                <span v-if="modalType === 'reserves'">(<a href="files/formato_actualizar_folio.xlsx" download="formato_actualizar_folio.xlsx">Descargar formato reservas</a>)</span>
                <span v-if="modalType === 'evaluations'">(<a href="https://storage.googleapis.com/pucon-custom/templates/ejemplo_carga_masiva_evaluaciones.xlsx">Descargar formato evaluaciones</a>)</span>
                </label>
                  <b-form-file :class="{ 'invalid-border': showInvalidText }"
                  ref="refInputFile"
                  id="file"
                  @change="changeFile"
                  :disabled="loading"
                  placeholder="Elije un archivo..."
                  accept=".xlsx"
                  v-model="formUpload.file"
                  />
                <div class="invalid-text" v-if="showInvalidText"> Este campo es requerido </div>
              </b-media-body>
            </div> 
          </template>  
        </form-render>
        <div v-if="rows.length > 0">
          <div class="my-1 invalid-text"> Se encontraron errores y no es posible procesar el archivo <feather-icon class="text-danger" icon="AlertCircleIcon"/></div>
          <table-render class=" mx-0 p-0 col-md-12" :rows="rows" :schema="schema"/>
        </div>
      </div>      
    </b-modal>
  </div>
</template>

<script>
import PickupsService from './pickups.service'
export default {
  props: ['modalTitle', 'modalType'],
  data () {
    return {
      formUpload: {
        file: null,
        email: ''
      },
      showInvalidText: false, 
      fields: [],
      loading: false,
      isError: false,
      rows: [],
      schema: [
        { label: 'Fila', key: 'row' },
        { label: 'Error', key: 'message' }
      ],
      pickupsService: new PickupsService(this),
      keyFormRender: 0
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'email', label: 'E-mail', validation: 'required', clearable: true, containerClass: 'container-info col-sm-12 col-md-12'},
        {name: 'slot1', clearable: true, containerClass: 'container-info col-sm-12 col-md-12 col-lg-12', useSlot: true}
      ]
    },
    changeFile (e) {
      this.formUpload.file = e.target.files[0]
      this.showInvalidText = false
    },
    close () {
      this.formUpload = {}
      this.showInvalidText = false
      this.rows = []
    },
    ok(e) {
      e.preventDefault()
      if (!this.formUpload.file) this.showInvalidText = true
      this.$refs.formUpload.checkForm()
    },
    onAccept(data) {
      data.file = this.formUpload.file
      if (!data.file) return
      this.uploadFile(data)
    },
    uploadFile(params) {
      this.loading = true
      this.isError = false

      this.pickupsService.uploadFileExcel('postExcelUpload', params, this.$session.get('cas_user'), this.modalType)
        .then(resp => {
          this.loading = false
          this.$bvModal.hide('modalPickupUpload')
          this.$success(this.$t('msg-succes-file-upload'))
          this.$emit('updatePickups', true)
        })
        .catch(err => {
          this.loading = false
          console.error(err, 'err')
          switch (err.code) {
          case 400:
            if (Array.isArray(err.errors)) {
              err.errors.map(error => {
                const errorObj = {
                  row: error.line,
                  message: error.errors[0]
                }
                this.rows.push(errorObj)
              })
            } else this.$alert(this.$t('msg-alert-file-no-data'))
            break
          case 422:
            this.$alert(this.$t('msg-alert-file-empty'))
          }
        })
    }
  }
}
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Subir';
}
.invalid-text {
  font-size: 0.857rem;
  color: #ea5455;
}
.invalid-border {
  border: solid 1px #ea5455;
  border-radius: 5px;
}
</style>