<template>
  <div>
    <b-modal id="modalFilters" size="md" :title="$t('advanced-search')" hide-footer no-close-on-esc no-close-on-backdrop centered>
     <form-render :fields="fields" :form="formFilters" :buttonSend="buttonSend" @send="onAccept" containerButtonsClass="col-sm-12 container-button" class="mb-1" ref="formRenderfilterPickup">
        <template #buttons>
          <!-- <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>   -->
        </template>
     </form-render>
    </b-modal>
  </div>
</template>
<script>

import PickupsService from './pickups.service'

export default {
  name: 'modal-advanced-filters',
  props: ['pickupFilters', 'clearFilters'],
  data () {
    return {
      buttonSend: {text: 'Buscar', color: 'warning', title: 'Filtrar', class: 'offset-md-9'},
      fields: [],
      formFilters: {},
      pickup_type: [
        {text: 'Normal', code: 'normal', id: 1},
        {text: 'Interno', code: 'internal', id: 2},
        {text: 'Devolución', code: 'return', id: 3},
        {text: 'Normal a partir de frecuente', code: 'normal_frequent', id: 4},
        {text: 'Todos', code: 'all', id: 5}
      ],
      pickupsService: new PickupsService(this)
    }
  },
  watch: {
    clearFilters () {
      this.formFilters = {}
    },
    'formFilters.carrier' () {
      if (!this.formFilters.carrier) this.formFilters.carrier_service = null
      else this.getCarrierServices()
      // if (this.formFilters.carrier && !this.formFilters.carrier_service) this.getCarrierServices()
    }
    // formFilters (newVal, oldVal) {
    //   Object.keys(this.formFilters).forEach(key => {
    //     if (this.formFilters[key] === null) delete this.formFilters[key]
    //   })
    //   this.$emit('modalFilters', this.formFilters)
    //   // this.formFilters = {}
    // }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    onAccept (data) {
      const allFilters = {...this.pickupFilters, ...data}
      this.$emit('sendModalFilters', allFilters)
      // this.formFilters = {}
    },
    setInitialData () {
      this.fields = [
        {
          fieldType: 'FieldSelect', 
          name: 'carrier', 
          label: 'Courier', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: { fx: this.getCarriers, nChars: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }
        },
        {
          fieldType: 'FieldSelect', 
          clearable: true, 
          label: 'Servicio', 
          name: 'carrier_service', 
          containerClass: 'col-sm-12 container-info', 
          dependency: 'carrier'
        },
        {
          fieldType: 'FieldSelect', 
          name: 'company', 
          label: 'Empresa', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: {fx: this.searchShippers, nChars: 3}
        },
        {
          fieldType: 'FieldSelect', 
          name: 'marketplace', 
          label: 'Marketplace', 
          containerClass: 'col-sm-12 container-info', 
          searchOnType: {fx: this.getMarketplaces, nChars: 3}
        },
        {
          fieldType: 'FieldSelect', 
          name: 'pickup_type', 
          label: 'Tipo de retiro', 
          containerClass: 'col-sm-12 container-info',
          options: this.pickup_type
        },
        {
          fieldType: 'FieldInput', 
          name: 'creator_user_email', 
          label: 'Correo del creador', 
          containerClass: 'col-sm-12 container-info'
        }

      ]
      this.getCarriers()
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderfilterPickup.checkForm()
    },
    searchShippers (value) {
      return this.pickupsService.callService('getShippers', {name: value}, null)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1}`}))
        })
        .catch(error => console.error(error))
    },
    getCarriers () {
      return this.pickupsService.callService('getCarriersNY')
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name} - ${el.code}`}))
        }).catch(err => {
          console.error(err)
        })
    },
    /**
     * Función para obtener los servicios de un carrier 
     */
    getCarrierServices () {
      if (this.formFilters.carrier) {
        const qry = {
          country_id: this.formFilters.carrier.countryId,
          carrier_id: this.formFilters.carrier.id
        }
        this.pickupsService.callService('getCarrierServicesNY', null, qry)
          .then(response => {
            const index = this.selectionIndex('carrier_service')
            const selectOptions = response.data.map(service => ({ ...service, text: service.name }))
            this.fields[index].options = selectOptions
            this.$refs.formRenderfilterPickup.updateUIForm()
          }).catch(err => {
            console.error(err)
          })
      }
    },
    getMarketplaces (value) {
      return this.pickupsService.callService('getOrganizations', {name: value})
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name}`}))
        }).catch(err => {
          console.error(err)
        })
    },
    cleanFilter () {
      this.formFilters = {}
      this.$emit('sendModalFilters', null)
    },
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    }
  }
}
</script>
<style lang="scss">
  
</style>