<template>
  <section id="pickups">
    <b-card>
      <form-render class="mb-2" :key="keyFormRender" :fields="fields" :buttonSend="buttonSend" :form.sync="formFilter" @send="filterForm" containerButtonsClass="col-sm-12 col-md-12 col-lg-7 mt-2">  <!--col-md-4 col-xl-3 mt-2  -->
        <template #buttons>
          <b-button variant="outline-light" v-b-tooltip.hover :title="$t('Limpiar filtros')" class="ml-2" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>  
          <b-button class="search-advanced" variant="link" @click="openAdvancedFiltersModal">{{ $t('advanced-search') }}</b-button>
        </template>
        <template #extra>
          <div class="mt-2 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
            <div class="row">
              <div class="col-md-12" v-b-tooltip="!enableExport ? $t('msg-alerta-export-pickups-only-date') : ''"> <!-- msg-alerta-export-pickups -->
                <b-button @click="downloadExcel" :disabled="!enableExport || loading.excel" class="push-right" variant="success">
                  <feather-icon v-if="!loading.excel" icon="DownloadIcon"/>
                  <i v-else :class="['fa', 'fa-spinner', 'fa-spin']"/> 
                  {{$t('Exportar a excel')}}</b-button>
                <div style="float: left; margin-right: 5px; margin-top: 8px">
                  <feather-icon v-show="!enableExport" class="text-danger" icon="AlertCircleIcon"/>
                </div>
              </div>
            </div>

            <div class="row mx-1">
              <div class="col-md-12">
                <b-dropdown block text="Actualizar información" variant="success">
                  <b-dropdown-item @click="showPickupUploadModal('reserves')">
                    <font-awesome-icon v-if="!loading.excel" :icon="['fa', 'upload']"/> Actualizar reservas
                  </b-dropdown-item>
                  <b-dropdown-item @click="showPickupUploadModal('evaluations')"><font-awesome-icon :icon="['fa', 'file']" /> Actualizar evaluaciones</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </template>
      </form-render>
      <table-render v-show="!loading.first" id="listPickups" :actions="actions" :schema="schema" :rows="rows" :loading="loading.pickups">
        <template #carrier_pickup_number="scope">
          <div class="d-flex align-items-center">
            <feather-icon 
              v-show="!scope.rowdata.carrier_pickup_created && scope.rowdata.carrier.is_integrated === 1 && ['normal', 'return'].includes(scope.rowdata.type.code)"
              icon="AlertTriangleIcon"
              class="text-warning"
              v-b-tooltip.hover 
              :title="$t('msg-failed-create-in-carrier') "/> 
            <span class="ml-1"> {{ scope.rowdata.carrier_pickup_number }} </span>
          </div>
        </template>
      </table-render>
      <pagination v-show="!loading.first" :pagination="pagination" :noDigits="true" :showSize="true"/>
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
    <modal-pickup-detail ref="modalPickupDetail" :pickup.sync="currentPickup"></modal-pickup-detail>
    <modal-pickup-logs ref="modalPickupLogs" :pickup.sync="currentPickupLogs"></modal-pickup-logs>
    <modal-pickup-upload ref="modalPickupUpload" :modalTitle="modalTitle" :modalType="modalType" @updatePickups="updatePickupsList"></modal-pickup-upload>
    <modal-filters ref="modalFilters" :pickupFilters="formFilter" :clearFilters="clearModalFilters" @sendModalFilters="onAcceptModalFilters" @modalFilters="updateModalFilters"></modal-filters>
  </section>
</template>

<script>
import ModalPickupDetail from './ModalPickupDetail.vue'
import ModalPickupLogs from './ModalPickupLogs.vue'
import PickupsService from './pickups.service'
import ModalFilters from './ModalFilters.vue'
import ModalPickupUpload from './ModalPickupUpload.vue'
import * as moment from 'moment'
import LoadingIcon from '@/Icons/LoadingIcon.vue'

export default {
  components: { ModalPickupDetail, ModalPickupLogs, ModalPickupUpload, ModalFilters },
  data () {
    return {
      buttonSend: {icon: 'SearchIcon', color: 'warning', title: 'Filtrar' },
      loading: {
        first: true,
        pickups: true,
        excel: false
      },
      actions: [],
      schema: [],
      rows: [],
      fields: [],
      modalAdvandecFilters: {},
      clearModalFilters: 0,
      formFilter: {},
      advancedFilters: {},
      payloadFilter: {},
      pagination: { page: 1, limit: 20, total: 1, loading: false },
      keyFormRender: 0,
      showData: false,
      enableExport: false,
      currentPickup: null,
      initialDateRange: {
        start: moment().add(-15, 'days').toDate(),
        end: moment().add(3, 'days').toDate()
      },
      currentPickupLogs: null,
      modalTitle: null,
      modalType: null,
      pickupsService: new PickupsService(this),
      PICKUP_TYPES: {
        NORMAL: 'normal',
        RETURN: 'return',
        NORMAL_FREQUENT: 'normal_frequent',
        INTERNAL: 'internal'
      }
    }
  },
  watch: {
    'pagination.page' () {
      this.getFullData()
    },
    'pagination.limit' () {
      this.getFullData()
    }
  },
  mounted () {
    this.initializeData()
  },
  methods: {
    initializeData () {
      this.fields = [
        {
          fieldType: 'FieldDatepicker', 
          name: 'pickup_date', 
          label: 'Fecha de retiro', 
          range: true,
          containerClass: 'col-sm-12 col-md-6 col-lg-2'
        },
        {
          fieldType: 'FieldInput', 
          name: 'carrier_pickup_number', 
          label: 'Reserva courier o N° reserva envíame', 
          clearable: true, 
          containerClass: 'col-sm-12 col-md-6 col-lg-3'
        }
      ]
      this.schema = [
        { label: 'N° Reserva Envíame', sortable: true, key: 'identifier' },
        { label: 'Seller', sortable: true, key: 'company.name' },
        { label: 'Fecha de retiro', sortable: true, key: 'pickup_date' },
        { label: 'Rango horario', sortable: true, key: 'range_time' },
        { label: 'Envíos', sortable: true, key: 'qty' },
        { label: 'Tipo de retiro', sortable: true, key: 'type.name' },
        { label: 'Dirección', sortable: true, key: 'address.full_address' },
        { label: 'Courier', sortable: true, key: 'carrier.name' },
        { label: 'Servicio', sortable: true, key: 'service_type.name' },
        { label: 'Contacto', sortable: true, key: 'contact.name' },
        { label: 'Estado', sortable: true, key: 'status.name' },
        { label: 'Reserva courier', sortable: true, useSlot: true, key: 'carrier_pickup_number' },
        { label: 'Estado de courier', sortable: true, key: 'status_carrier.name' },
        { label: 'Usuario Creador', sortable: true, key: 'creator_user.full_name' },
        { label: 'Acciones', sortable: true, key: 'actions', style: {width: '120px'} }
      ]
      this.actions = [
        {action: id => this.showPickupDetail(id), icon: 'EyeIcon', color:'warning', text: 'Ver detalles'},
        {action: id => this.showPickupLogsModal(id), icon: 'ClipboardIcon', color:'warning', text: 'Ver registros'},
        {
          action: id => this.reinjectPickup(id),
          icon: 'RepeatIcon',
          color:'warning',
          text: 'Reinyectar retiro',
          visible: (pickup) => pickup.carrier_pickup_created === false && pickup.carrier.is_integrated === 1 && ['normal', 'return'].includes(pickup.type.code)
        }
      ]
      this.formFilter = {
        pickup_date: this.initialDateRange
      }
      this.filterList(this.formFilter)
      // this.getFullData()
    },
    filterForm(form) {
      this.filterList({...this.advancedFilters, ...form})
    },
    filterList (data) {
      this.payloadFilter = {}
      Object.keys(data).map(key => {
        switch (key) {
        case 'carrier':
          this.payloadFilter[key] = data[key].code
          break
        case 'pickup_date':
          if (data.pickup_date) {
            this.payloadFilter['pickup_date_from'] = this.$options.filters.moment(data.pickup_date.start, 'YYYY-MM-DD')
            this.payloadFilter['pickup_date_to'] = this.$options.filters.moment(data.pickup_date.end, 'YYYY-MM-DD')
          } 
          break
        case 'pickup_type':
          this.payloadFilter['type'] = data['pickup_type'].code
          break
        case 'carrier_service':
          this.payloadFilter['service_type'] = data['carrier_service'].code
          break
        case 'creator_user_email':
          this.payloadFilter['creator_user'] = data['creator_user_email']
          break
        default:
          this.payloadFilter[key] = data[key].id || data[key]
          break
        }
      })
      this.enableExport = !!data.pickup_date // && !!data.carrier  
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getFullData()
    },
    cleanFilter () {
      this.enableExport = false
      this.formFilter = {
        pickup_date: this.initialDateRange
      }
      this.clearModalFilters++
      this.filterList(this.formFilter)
    },

    getFullData () {
      this.loading.pickups = true
      const queryParams = {
        limit: this.pagination.limit, 
        page: this.pagination.page, 
        ...this.payloadFilter,
        old: true
      }
      this.pickupsService.callService('getAdminPickups', queryParams, queryParams)
        .then(response => {
          this.setPickupRows(response.data)
          this.loading.first = false
          this.loading.pickups = false
        }).catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
    },
    setPickupRows (data) {
      // data = data.map(pickup =>  ({...pickup, pickup_date: pickup.pickup_date.split(' ')[0]}))
      data = data.map(pickup =>  {
        if (pickup.creator_user) pickup.creator_user.full_name = `${pickup.creator_user.first_name} ${pickup.creator_user.last_name}`
        return pickup
      })
      this.rows = data.map(el => ({...el, id: el.identifier}))
    },
    downloadExcel () {
      this.loading.excel = true
      const payload = {
        // carrier: this.formFilter.carrier.code.toLowerCase(),
        // pickup_date: this.$options.filters.moment(this.payloadFilter.pickup_date, 'DD-MM-YYYY'),
        ...this.payloadFilter
      }
 
      this.pickupsService.callService('downloadPickups', payload, payload)
        .then(resp => {
          if (resp.data.code === 'OK') {
            window.open(resp.data.url, '_blank')
          } else {
            this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('Ocurrió un problema al exportar el archivo'))
        })
        .finally(end => {
          this.loading.excel = false
        })
    },
    showPickupDetail (id) {
      const shipper_id = this.rows.filter(el => el.id === id)[0].company.id
      this.$refs.modalPickupDetail.loading = true
      this.$bvModal.show('modalPickupDetail')
      this.pickupsService.callService('getPickup', null, { id, shipper_id })
        .then(resp => {
          this.currentPickup = resp.data
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-error-load-pickup'))
        })
        .finally(end => {
          this.$refs.modalPickupDetail.loading = false
        })
    },
    showPickupLogsModal (id) {
      this.$refs.modalPickupLogs.loading = true
      this.$bvModal.show('modalPickupLogs')
      const shipper_id = this.rows.filter(el => el.id === id)[0].company.id
      const params = {
        id, 
        shipper_id
      }
      this.pickupsService.callService('getPickupLogs', null, params)
        .then(resp => {
          this.currentPickupLogs = resp.data
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al cargar el detalle del retiro')
        })
    },
    showPickupUploadModal (type) {
      if (type === 'reserves') {
        this.modalTitle = 'Actualizar reservas'
      } else if (type === 'evaluations') {
        this.modalTitle = 'Actualizar evaluaciones'
      }
      this.modalType = type
      this.$bvModal.show('modalPickupUpload')
    },
    updatePickupsList (data) {
      if (data) this.cleanFilter()
    },
    openAdvancedFiltersModal () {
      this.$bvModal.show('modalFilters')
    },
    onAcceptModalFilters (modalData) {
      this.advancedFilters = modalData
      const allFilters = {...this.advancedFilters, ...this.formFilter}
      this.filterList(allFilters)
      this.$bvModal.hide('modalFilters')
    },
    updateModalFilters (data) {
      this.formFilter = {...this.formFilter, ...data}
    },

    reinjectPickup(id) {
      const reinjectAction = this.actions.find(action => action.text === 'Reinyectar retiro')
      reinjectAction.icon = null
      reinjectAction.component = LoadingIcon
      reinjectAction.disabled = true

      const pickup = this.rows.find(r => r.id === id)
      const identifiers = [id]
      const propertyName =
        pickup.type.code === this.PICKUP_TYPES.NORMAL ? 'normal_identifiers' : 'return_identifiers'

      const payload = {
        [propertyName]: identifiers
      }

      this.pickupsService.callService('reinjectPickups', payload)
        .then(resp => {
          const response = resp.data[0].response
          if (response.pickup_number) {
            const {pickup_number} = response
            this.$success(this.$t('msg-success-reinject-pickup', {pickup_number}))
            this.updateCarrierPickupNumber(id, response.pickup_number)
          } else {
            const message = response.message
            this.$alert(this.$t('msg-error-reinject-pickup', {message}))
          }
        })
        .catch(err => {
          console.error(err)
          this.$alert(this.$t('msg-error-reinject-pickup'))
        })
        .finally(() => {
          reinjectAction.icon = 'RepeatIcon'
          reinjectAction.component = null
          reinjectAction.disabled = false
        })
    },
    /**
     * Actualiza el número de reserva del carrier en la lista de retiros
     * luego de una reinyección exitosa
     * Esto para que no se visualice el ícono de alerta y se pueda ver el número de reserva
     * en la tabla
     * @param {string} id Identificador del retiro
     * @param {string} carrierPickupNumber Número de reserva del carrier
     */
    updateCarrierPickupNumber(id, carrierPickupNumber) {
      const pickup = this.rows.filter(el => el.id === id)[0]
      pickup.carrier_pickup_number = carrierPickupNumber
      pickup.carrier_pickup_created = true
    }
  }
}
</script>

<style lang="scss">

.search-advanced {
    text-decoration: underline !important;
}
</style>
